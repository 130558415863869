import { PLAYBACK_PAGE_ROUTE } from "@/constants/menu";
import { adjustGranularityForPeriod } from "@/helpers/filter";
import { useFilterStore } from "@/store/filterStore";
import { defineNuxtRouteMiddleware } from "nuxt/app";

export default defineNuxtRouteMiddleware((_, from) => {
    const filterStore = useFilterStore();
    if (from.name === PLAYBACK_PAGE_ROUTE) {
        adjustGranularityForPeriod(filterStore);
    }
  });